import React, {useState, useEffect, useRef} from 'react';
import logo from './instantmixstudio.png';
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Countdown from 'react-countdown';
function VocalArtistOnboard(props)
{


  


return (<Modal
  show={props.show}
  onHide={props.onHide}
  centeredsize="lg"
  aria-labeledby="contained-modal-title-vcenter"
  >
  <Modal.Header closeButton>
  <Modal.Title>Vocal Artist Onboarding</Modal.Title>
</Modal.Header>

<Modal.Body>

<form>
<fieldset>


<legend>Register your Account</legend>
<p>Add your details to create an account</p>


<div >
  <label >First Name</label>
  <div >
  <input name="first_name" type="text" placeholder="Enter first name"  />
  </div>
  <div></div>
</div>

<div>
  <label>Last Name<span> *</span></label>
  <div>
  <input bname="last_name" type="text" placeholder="Enter last name" required="" />
  </div>
  <div></div>
</div>

<div>
  <label for="agilefield-3">Email<span > *</span></label>
  <div >
  <input maxlength="250" id="agilefield-3" name="email" type="email" placeholder="Enter email" required="" />
  </div>
  <div class="agile-custom-clear"></div>
</div>

<div >
  <label for="agilefield-4">Stage Name<span > *</span></label>
  <div class="agile-field-xlarge agile-field">
  <input maxlength="250" id="agilefield-4" name="company" type="text" placeholder="Enter your stage name" required="" />
  </div>
  <div class="agile-custom-clear"></div>
</div>

<div class="agile-group">
  <label for="agilefield-2">Phone</label>
  <div>
  <input maxlength="250" id="agilefield-2" name="phone" type="text" placeholder="Enter your phone" />
  </div>
  <div></div>
</div>

<div>
  <label for="inline_checkboxes_1603007518991">Communication Permissions</label>
  <div >
    <label for="inline_checkboxes_1603007518991-0">
      <input type="checkbox" name="inline_checkboxes_1603007518991-0" id="inline_checkboxes_1603007518991-0" value="Can SMS" checked="checked" /><i></i>
      Can SMS
    </label>
    <label for="inline_checkboxes_1603007518991-1" >
      <input type="checkbox" name="inline_checkboxes_1603007518991-1" id="inline_checkboxes_1603007518991-1" value="Can Email" checked="checked" /><i></i>
      Can Email
    </label>
  </div>
</div>


<div>
  <label  for="agilefield-7">Birthdate (Must be 18 or older to register)<span > *</span></label>
  <div id="agilefield-7" >
	<select id="choose-date-agilefield-7"  onchange="prepareDateTypeValue(event);">
		<option value="">Day</option>
		<option value="01">01</option>
		<option value="02">02</option>
		<option value="03">03</option>
		<option value="04">04</option>
		<option value="05">05</option>
		<option value="06">06</option>
		<option value="07">07</option>
		<option value="08">08</option>
		<option value="09">09</option>
		<option value="10">10</option>
		<option value="11">11</option>
		<option value="12">12</option>
		<option value="13">13</option>
		<option value="14">14</option>
		<option value="15">15</option>
		<option value="16">16</option>
		<option value="17">17</option>
		<option value="18">18</option>
		<option value="19">19</option>
		<option value="20">20</option>
		<option value="21">21</option>
		<option value="22">22</option>
		<option value="23">23</option>
		<option value="24">24</option>
		<option value="25">25</option>
		<option value="26">26</option>
		<option value="27">27</option>
		<option value="28">28</option>
		<option value="29">29</option>
		<option value="30">30</option>
		<option value="31">31</option>
	</select>
	<select id="choose-month-agilefield-7" onchange="prepareDateTypeValue(event);">
		<option value="">Month</option>
		<option value="Jan">Jan</option>
		<option value="Feb">Feb</option>
		<option value="Mar">Mar</option>
		<option value="Apr">Apr</option>
		<option value="May">May</option>
		<option value="June">June</option>
		<option value="July">July</option>
		<option value="Aug">Aug</option>
		<option value="Sep">Sep</option>
		<option value="Oct">Oct</option>
		<option value="Nov">Nov</option>
		<option value="Dec">Dec</option>
		
	</select>
	<select id="choose-year-agilefield-7"  onchange="prepareDateTypeValue(event);">
		<option value="" selected="1">Year</option>
		<option value="2017">2017</option>
		<option value="2016">2016</option>
		<option value="2015">2015</option>
		<option value="2014">2014</option>
		<option value="2013">2013</option>
		<option value="2012">2012</option>
		<option value="2011">2011</option>
		<option value="2010">2010</option>
		<option value="2009">2009</option>
		<option value="2008">2008</option>
		<option value="2007">2007</option>
		<option value="2006">2006</option>
		<option value="2005">2005</option>
		<option value="2004">2004</option>
		<option value="2003">2003</option>
		<option value="2002">2002</option>
		<option value="2001">2001</option>
		<option value="2000">2000</option>
		<option value="1999">1999</option>
		<option value="1998">1998</option>
		<option value="1997">1997</option>
		<option value="1996">1996</option>
		<option value="1995">1995</option>
		<option value="1994">1994</option>
		<option value="1993">1993</option>
		<option value="1992">1992</option>
		<option value="1991">1991</option>
		<option value="1990">1990</option>
		<option value="1989">1989</option>
		<option value="1988">1988</option>
		<option value="1987">1987</option>
		<option value="1986">1986</option>
		<option value="1985">1985</option>
		<option value="1984">1984</option>
		<option value="1983">1983</option>
		<option value="1982">1982</option>
		<option value="1981">1981</option>
		<option value="1980">1980</option>
		<option value="1979">1979</option>
		<option value="1978">1978</option>
		<option value="1977">1977</option>
		<option value="1976">1976</option>
		<option value="1975">1975</option>
		<option value="1974">1974</option>
		<option value="1973">1973</option>
		<option value="1972">1972</option>
		<option value="1971">1971</option>
		<option value="1970">1970</option>
		<option value="1969">1969</option>
		<option value="1968">1968</option>
		<option value="1967">1967</option>
		<option value="1966">1966</option>
		<option value="1965">1965</option>
		<option value="1964">1964</option>
		<option value="1963">1963</option>
		<option value="1962">1962</option>
		<option value="1961">1961</option>
		<option value="1960">1960</option>
		<option value="1959">1959</option>
		<option value="1958">1958</option>
		<option value="1957">1957</option>
		<option value="1956">1956</option>
		<option value="1955">1955</option>
		<option value="1954">1954</option>
		<option value="1953">1953</option>
		<option value="1952">1952</option>
		<option value="1951">1951</option>
		<option value="1950">1950</option>
		<option value="1949">1949</option>
		<option value="1948">1948</option>
		<option value="1947">1947</option>
		<option value="1946">1946</option>
		<option value="1945">1945</option>
		<option value="1944">1944</option>
		<option value="1943">1943</option>
		<option value="1942">1942</option>
		<option value="1941">1941</option>
		<option value="1940">1940</option>
		<option value="1939">1939</option>
		<option value="1938">1938</option>
		<option value="1937">1937</option>
		<option value="1936">1936</option>
		<option value="1935">1935</option>
		<option value="1934">1934</option>
		<option value="1933">1933</option>
		<option value="1932">1932</option>
		<option value="1931">1931</option>
		<option value="1930">1930</option>
		<option value="1929">1929</option>
		<option value="1928">1928</option>
		<option value="1927">1927</option>
		<option value="1926">1926</option>
		<option value="1925">1925</option>
		<option value="1924">1924</option>
		<option value="1923">1923</option>
		<option value="1922">1922</option>
		<option value="1921">1921</option>
		<option value="1920">1920</option>
		<option value="1919">1919</option>
		<option value="1918">1918</option>
		<option value="1917">1917</option>
		<option value="1916">1916</option>
		<option value="1915">1915</option>
		<option value="1914">1914</option>
		<option value="1913">1913</option>
		<option value="1912">1912</option>
		<option value="1911">1911</option>
		<option value="1910">1910</option>
		<option value="1909">1909</option>
		<option value="1908">1908</option>
		<option value="1907">1907</option>
		<option value="1906">1906</option>
		<option value="1905">1905</option>
	</select>
  </div>
  <div><input id="date-hiddeninput-agilefield-7" type="" name="agilefield-7" value="" required="" /></div>
  <div></div>
</div>


<div id="embed-crm-js">
  <label >&nbsp;</label>
  <div>
    <button type="submit" >Register Now</button>
    <br /><span id="agile-error-msg"></span>
  </div>
</div>

</fieldset>
</form>


</Modal.Body>


  </Modal>);
}

function BeatsMixerOnboard(props)
{
  return (<Modal
    show={props.show}
    onHide={props.onHide}
    centeredsize="lg"
    aria-labeledby="contained-modal-title-vcenter"
    >
    <Modal.Header closeButton>
    <Modal.Title>Modal title</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Modal body text goes here.</p>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary">Close</Button>
    <Button variant="primary">Save changes</Button>
  </Modal.Footer>
    </Modal>);
}

function WriterOnboard(props)
{
  return (<Modal
    show={props.show}
    onHide={props.onHide}
    centeredsize="lg"
    aria-labeledby="contained-modal-title-vcenter"
    >
    <Modal.Header closeButton>
    <Modal.Title>Modal title</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <p>Modal body text goes here.</p>
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary">Close</Button>
    <Button variant="primary">Save changes</Button>
  </Modal.Footer>
    </Modal>);
}



function App() {

  const [showVocalArtist, setShowVocalArtist] = useState(false);




  const handleVocalArtist = evt => setShowVocalArtist(true);
  
  
  const handleBeatsMixer = evt => {
    
  };
  
  const handleWriter = evt => {
    
  };

  const handleVocalArtistClose = () => 
    setShowVocalArtist(false);
  

  


  return (
    <>
      <VocalArtistOnboard
        show={showVocalArtist}
        onHide={handleVocalArtistClose}
       
       
      ></VocalArtistOnboard>
      <div className="App">

        <header className="App-header">
          <div className="App-header-nav">
            <Navbar bg="dark">
              <Navbar.Brand href="#home"> <img src={logo} alt="logo" /></Navbar.Brand>
            </Navbar>
          </div>
          <div className="App-body">
            <div style={{height:'40vh'}}><Countdown date={1609459200} renderer={props => <div> {props.days} Days {props.hours} Hours {props.minutes} Minutes {props.seconds} Seconds {props.milliseconds} milliseconds</div>} /></div>
            <Container fluid>
              <Row noGutters={true}>
                <Col><Button variant="primary" size="lg" onClick={handleVocalArtist}>I'm a Vocal Artist</Button></Col>
                <Col><Button variant="primary" size="lg" onClick={handleBeatsMixer}>I'm a Mixer Master</Button></Col>
                <Col><Button variant="primary" size="lg" onClick={handleWriter}>I'm a Song Writer</Button></Col>
                <Col><Button variant="primary" size="lg" onClick={handleWriter}>I'm a Beats Maker</Button></Col>
              </Row>
            </Container>
          </div>
        </header>
      </div>
    </>
  );
}

export default App;
