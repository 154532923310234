import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


var prod = false;
if (window.location.protocol !== "https:" && prod) {
  window.location.href =
    "https:" +
    window.location.href.substring(window.location.protocol.length);
}

console.log(window.location.hostname, prod);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js", {
    updateViaCache: "none",
    scope: "/"
  });
}

let firebaseConfig = {
  apiKey: "AIzaSyAksEEEJNa7yKLQmL1lJc92-wT5Jewa2CE",
  authDomain: "instantmix-223ff.firebaseapp.com",
  databaseURL: "https://instantmix-223ff.firebaseio.com",
  projectId: "instantmix-223ff",
  storageBucket: "instantmix-223ff.appspot.com",
  messagingSenderId: "137077983507",
  appId: "1:137077983507:web:ae29944bc2d6c29377b2e6",
  measurementId: "G-XSQHJPMMR3"
};

// Your web app's Firebase configuration

// Initialize Firebase
window.firebase.initializeApp(firebaseConfig);

console.log("auth", window.firebase.auth());

window.firebase.auth().onAuthStateChanged(function (user) {

  console.log("user", user);

  if (user) {

    let root = document.getElementById('root');
    window.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(token => {
        localStorage.setItem(
          "instantmixstudio.token",
          JSON.stringify({ id_token: token })
        );
        ReactDOM.render(
          <React.StrictMode>
          <App />
        </React.StrictMode>,
          root
        );

        root.style.display = "block";
      });
  } else {
    // Initialize the FirebaseUI Widget using Firebase.
    var ui = new window.firebaseui.auth.AuthUI(window.firebase.auth());

    ui.start("#firebaseui-auth-container", {
      signInSuccessUrl: "/shyft-auto",
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          //console.log(authResult);

          window.firebase
            .auth()
            .currentUser.getIdToken()
            .then(token => {
              localStorage.setItem(
                "instantmixstudio",
                JSON.stringify({ id_token: token })
              );

              document.getElementById("root").style.display = "block";
              ReactDOM.render(    <React.StrictMode>
                <App />
              </React.StrictMode>, document.getElementById('root'));
            });

          //console.log(localStorage.klouddms);'

          //window.location.href = "/shyft-auto";
          return false;
        }
      },
      signInOptions: [
        {
          provider: window.firebase.auth.TwitterAuthProvider.PROVIDER_ID
        }
      ]
      // Other config options...
    });
  }
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
